import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from './context';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBvYXSda5TWuMLb_kAYT-9UlptM0weqNpA",
//   authDomain: "portfolio-771d1.firebaseapp.com",
//   projectId: "portfolio-771d1",
//   storageBucket: "portfolio-771d1.appspot.com",
//   messagingSenderId: "1021182022577",
//   appId: "1:1021182022577:web:b69accf056bee43144e390",
//   measurementId: "G-RGXKZ2N122"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);