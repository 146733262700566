import React, { useContext } from "react";
import "./about.css";
import { ThemeContext } from "../../context";

const About = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="a" style={{ backgroundColor: darkMode && "#2f2f2c" }}>
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-771d1.appspot.com/o/about2.png?alt=media&token=7a2a7ed5-e09b-42f8-a933-e2132b96e291" alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">Over mij</h1>
        <p className="a-sub">
          Ik ben Viktor van Puyvelde, student Toegepaste Informatica aan HOWEST. Met deze studie specialiseer ik me in cybersecurity.
        </p>
        <p className="a-desc">
          Ik ben afkomstig uit Sint-Pauwels, waar ik ook mijn lager onderwijs
          heb gedaan in de plaatselijke lagere school "De Zandloper." <br /> <br />
          In het Sint-Jozef-Klein-Seminarie te Sint-Niklaas, heb ik met glans
          het secundair onderwijs doorlopen. Daar heb ik mijn diploma
          Economie-Moderne Talen behaald. <br /> <br />
          In 2023 heb ik met succes mijn opleiding Toegepaste Informatica: Mobile & Enterprise Devoloper aan HoGent afgerond.
          Mijn stage voor deze studie heb ik gedaan bij het IT Consultancy bureau <a href="https://www.axxes.com/">Axxes</a>.
          <br /> <br />
          In 2024 heb ik me ingeschreven voor een één-jaar durende opleiding Cybersecurity aan Howest.
          <br /><br />
          Op mijn <a href="https://github.com/ViktorVanPuyvelde">GitHub</a>-pagina zijn al mijn projecten te vinden,
          zowel die ik voor schoolopdrachten als in mijn vrije tijd heb gemaakt.
          <br /><br />
          Naast mijn schoolcarrière ben ik ook nog full-time leider bij de
          scouts Mgr. Bermijn in mijn thuisdorp Sint-Pauwels.

        </p>
      </div>
    </div>
  );
};

export default About;
