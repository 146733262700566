import React, { useContext } from "react";
import "./experiences.css";
import ExperienceInfoItem from "../experienceInfoItem/ExperienceInfoItem";
import { ThemeContext } from "../../context";

const Experiences = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="e">
      <div className="e-left">
        <h1 className="e-title">Ervaringen - CV</h1>
        <p className="e-desc">
          Hieronder vindt u kort alle info over mijn carrière tot nu toe. <br />
          Wenst u een meer gedetailleerd document, aarzal dan zeker niet om mijn
          Curriculum Vitae te donwloaden via de knop hieronder!
        </p>
        <button className="dCV">
          <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-771d1.appspot.com/o/cv.pdf?alt=media&token=6dcd00a2-b100-4bbc-8e13-b8f407b3285a" target={"_blank"} download="CV_ViktorVanPuyvelde" rel="noreferrer">
            Download CV
          </a>
        </button>
        <div className="info-items">
          <div className="info-item">
            <h1 className="info-heading">Onderwijs</h1>

            <ExperienceInfoItem
              title="Lager onderwijs"
              items={["De Zandloper, Sint-Pauwels"]}
              darkMode={darkMode}
              long={false}
            />
            <ExperienceInfoItem
              title="Secundair onderwijs"
              items={["Economie-Moderne Talen - SJKS, Sint-Niklaas"]}
              darkMode={darkMode}
              long={false}
            />
            <ExperienceInfoItem
              title="Hoger onderwijs"
              items={["Toegpaste Informatica - HOGENT", "Cybersecurity - HOWEST"]}
              darkMode={darkMode}
              long={false}
            />
          </div>
          <div className="info-item">
            <h1 className="info-heading">Mijn skills</h1>

            <ExperienceInfoItem
              title="Front-end"
              items={["HTML", "CSS", "JavaScript", "REACT", "Blazor"]}
              darkMode={darkMode}
              long={false}
            />
            <ExperienceInfoItem
              title="Back-end"
              items={["Node", "MongoDB", "MySQL", "Firebase", "Java", "Python", "C#"]}
              darkMode={darkMode}
              long={false}
            />
            <ExperienceInfoItem
              title="Infrastructure"
              items={["Azure", "Docker", "Git"]}
              darkMode={darkMode}
              long={false}
            />
          </div>
          <div className="info-item">
            <h1 className="info-heading">Ervaringen</h1>

            <ExperienceInfoItem
              title="Studentenjobs"
              items={["B-Post - Sint-Niklaas", "Stage - Axxes IT Consultancy"]}
              darkMode={darkMode}
              long={false}
            />
          </div>
        </div>
      </div>
      {/* <div className="e-right">
        <div className="e-card bg"></div>
        <div className="e-card">
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-771d1.appspot.com/o/experiences.png?alt=media&token=cc8d46b5-b0b8-42b8-83b4-ded5e3fc2d7b" alt="" className="e-img" />
        </div>
      </div> */}
    </div>
  );
};

export default Experiences;
