import React, { useContext } from "react";
import "./intro.css";
import { ThemeContext } from "../../context";

const Intro = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">Hallo, mijn naam is</h2>
          <h1 className="i-name">Viktor Van Puyvelde</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">Student Toegepaste Informatica</div>
              <div className="i-title-item">Mobile & Enterprise Developer</div>
              <div className="i-title-item">Cybersecurity</div>
            </div>
          </div>
          <div className="i-desc">
            Ik ben student Toegepaste Informatica aan HOWEST en specialiseer me in cybersecurity
          </div>
        </div>
        <svg
          width="72px"
          height="72px"
          viewBox="0 0 24 24"
          fill="none"
          className="i-scroll"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 5L12.5303 4.46967C12.2374 4.17678 11.7626 4.17678 11.4697 4.46967L12 5ZM12 13L11.4697 13.5303C11.7626 13.8232 12.2374 13.8232 12.5303 13.5303L12 13ZM9.46967 6.46967C9.17678 6.76256 9.17678 7.23744 9.46967 7.53033C9.76256 7.82322 10.2374 7.82322 10.5303 7.53033L9.46967 6.46967ZM13.4697 7.53033C13.7626 7.82322 14.2374 7.82322 14.5303 7.53033C14.8232 7.23744 14.8232 6.76256 14.5303 6.46967L13.4697 7.53033ZM10.5303 10.4697C10.2374 10.1768 9.76256 10.1768 9.46967 10.4697C9.17678 10.7626 9.17678 11.2374 9.46967 11.5303L10.5303 10.4697ZM14.5303 11.5303C14.8232 11.2374 14.8232 10.7626 14.5303 10.4697C14.2374 10.1768 13.7626 10.1768 13.4697 10.4697L14.5303 11.5303ZM3.25 10V14H4.75V10H3.25ZM20.75 14V10H19.25V14H20.75ZM11.25 5V13H12.75V5H11.25ZM11.4697 4.46967L9.46967 6.46967L10.5303 7.53033L12.5303 5.53033L11.4697 4.46967ZM11.4697 5.53033L13.4697 7.53033L14.5303 6.46967L12.5303 4.46967L11.4697 5.53033ZM12.5303 12.4697L10.5303 10.4697L9.46967 11.5303L11.4697 13.5303L12.5303 12.4697ZM12.5303 13.5303L14.5303 11.5303L13.4697 10.4697L11.4697 12.4697L12.5303 13.5303ZM20.75 10C20.75 5.16751 16.8325 1.25 12 1.25V2.75C16.0041 2.75 19.25 5.99594 19.25 10H20.75ZM12 22.75C16.8325 22.75 20.75 18.8325 20.75 14H19.25C19.25 18.0041 16.0041 21.25 12 21.25V22.75ZM3.25 14C3.25 18.8325 7.16751 22.75 12 22.75V21.25C7.99594 21.25 4.75 18.0041 4.75 14H3.25ZM4.75 10C4.75 5.99594 7.99594 2.75 12 2.75V1.25C7.16751 1.25 3.25 5.16751 3.25 10H4.75Z"
            fill={darkMode ? "white" : "black"}
          />
        </svg>
      </div>
      <div className="i-right">
        <div className="i-bg"></div>
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-771d1.appspot.com/o/meCutOut.png?alt=media&token=b8bd0cf8-32ea-47cd-82d4-46c4e5d3303e" alt="" className="i-img" />
      </div>
    </div>
  );
};

export default Intro;
